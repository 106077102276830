import { HospitalSideBarConstants } from 'constants/SideBarConstants';
import { pagePaths } from 'constants/path';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLeaveModalStateStore } from 'store/useLeaveModalStateStore';
import { useLoginStore } from 'store/useLoginDataStore';
import { NormalLNBButton, ProminentLNBButton } from 'styles/Global/buttonStyles';

const HospitalSideBar = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const [path, setPath] = useState('');

    const { editState, movePageState, setIsLeaveModal, setClickType, setMovePath, returnSelectedAssessmentState, setIsReturnSelectedAssessment } = useLeaveModalStateStore();

    const { loginData, setLoginData } = useLoginStore();

    // sidebar hover 및 selected 적용을 위한 useEffect
    useEffect(() => {
        if (pathname === pagePaths.viewDetailPatient) {
            return setPath(pagePaths.managingPatients);
        } else if (pathname === pagePaths.viewDetaildiagnosis) {
            return setPath(pagePaths.diagnosisList);
        }
        return setPath(pathname);
    }, [pathname]);

    // 조건에 따라 sidebar 클릭 시 modal 띄우기
    const handleSideBarOnModal = (nowPagePath: string, listPath: string) => {
        if ((nowPagePath === pagePaths.viewDetaildiagnosis && editState) || ((nowPagePath === pagePaths.accountSetting || nowPagePath === pagePaths.addDiagnosis) && movePageState)) {
            if (nowPagePath === listPath) {
                return window.location.reload();
            } else {
                setClickType('sideBar');
                setMovePath(listPath);
                return setIsLeaveModal();
            }
        } else if (nowPagePath === pagePaths.viewDetaildiagnosis && returnSelectedAssessmentState) {
            setClickType('sideBar');
            setMovePath(listPath);
            return setIsReturnSelectedAssessment();
        } else if (nowPagePath === listPath) {
            return window.location.reload();
        } else {
            if (loginData.diagnosisTap !== 0) setLoginData({ ...loginData, diagnosisTap: 0 });
            return navigate(listPath);
        }
    };
    return (
        <>
            {HospitalSideBarConstants(path).map((list, idx) => {
                if (loginData.level === 10 && idx === 3) {
                    return (
                        <NormalLNBButton key={idx} $idx={idx} $path={path} $listPath={list.path} onClick={() => handleSideBarOnModal(pathname, list.path)}>
                            {list.icon}
                            {list.name}
                        </NormalLNBButton>
                    );
                } else if (loginData.level !== 10 && idx === 0) {
                    return (
                        <ProminentLNBButton key={idx} onClick={() => handleSideBarOnModal(pathname, list.path)}>
                            {list.icon}
                            {list.name}
                        </ProminentLNBButton>
                    );
                } else if (loginData.level !== 10) {
                    return (
                        <NormalLNBButton key={idx} $idx={idx} $path={path} $listPath={list.path} onClick={() => handleSideBarOnModal(pathname, list.path)}>
                            {list.icon}
                            {list.name}
                        </NormalLNBButton>
                    );
                }
            })}
        </>
    );
};

export default HospitalSideBar;
