import { Suspense, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import Loading from "components/Common/Loading";
import { useLoadingStore } from "store/useLoadingStore";
import { governmentPaths } from "constants/path";
import { GlobalStyles } from "styles/Global/globalStyles";
import GovernmentHeader from "./Header/GovernmentHeader";

function GovernmentApp() {
  const navigate = useNavigate();

  const { loading, setLoading } = useLoadingStore();

  // const { isLogoutModal, setIsLogoutModal } = useLeaveModalStateStore();

  useEffect(() => {
    axios.interceptors.request.use(
      (config) => {
        // setLoading(true);
        return config;
      },
      (error) => {
        // setLoading(false);
        return Promise.reject(error);
      }
    );
    axios.interceptors.response.use(
      (response) => {
        // setLoading(false);
        return response;
      },
      (error) => {
        localStorage.clear();
        // if (error.response.status === 534) {
        //     // setLoading(false);
        //     // setIsLogoutModal(true);
        //     return navigate(governmentPaths.agree, { replace: true });
        // }
        console.log(error, "axiosInterceptResponseError");
        // setLoading(false);
        return Promise.reject(error);
      }
    );
  }, []);
  return (
    <>
      <GlobalStyles />
      <AppLayout>
        <Loading loading={loading} />
        <Suspense fallback={<Loading loading={loading} />}>
          <GovernmentHeader />
          <InnerLayout>
            <Outlet />
          </InnerLayout>
        </Suspense>
      </AppLayout>
    </>
  );
}

export default GovernmentApp;

const AppLayout = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
`;

const InnerLayout = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 360px;
  width: 100vw;
  padding: 24px 16px 16px;
  margin-top: 52px;
`;
