import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { PageTitle } from 'styles/Global/commonStyles';
import { palette } from 'styles/Global/globalStyles';
import moment from 'moment';
import useToggle from 'hooks/CustomHooks/useToggle';
import { useLoginStore } from 'store/useLoginDataStore';
import { HospitalPageLabel, FilterWrapper, DiagnosisInnerLayout, DiagnosisLayout } from 'styles/Global/hospitalStyles';
import { calculatePageText } from 'constants/calculatePageConstants';
import { calculatePageApi } from 'api/Admin/calculatePageApi';
import { GetPaymentListApiResponse } from 'models/Admin/calculatePageApiTypes';
import AdminCalculatePagination from './AdminCalculatePagination';

const AdminCalculatePage = () => {
    const [afterSelectedDate, setAfterSelectedDate] = useState<string | null>(moment().format('YYYY/MM/DD'));
    const [beforeSelectedDate, setBeforeSelectedDate] = useState<string | null>(moment().subtract(1, 'months').format('YYYY/MM/DD'));
    const [initData, setInitData] = useState<GetPaymentListApiResponse['data']['list']>();

    const [selectedPeriod, setSelectedPeriod] = useState<number>(1);
    const [selectedSearchFilter, setselectedSearchFilter] = useState<number>(0);
    const [searchValue, setSearchValue] = useState<string>('');
    const [page, setPage] = useState(1);

    const [onBeforeCalendar, setOnBeforeCalendar] = useToggle();
    const [onAfterCalendar, setOnAfterCalendar] = useToggle();
    const [onSelectedPeriodDropdown, setOnSelectedPeriodDropdown] = useToggle();
    const [onSearchFilterDropdown, setOnSearchFilterDropdown] = useToggle();

    const { loginData } = useLoginStore();

    // const [totalPages, setPage, currentPage, pageGroup, handlePageGroup, currentData] = usePaginationStateHooks(filterData || []);

    // period filter
    useEffect(() => {
        switch (selectedPeriod) {
            case 0:
                setAfterSelectedDate(moment().format('YYYY/MM/DD'));
                setBeforeSelectedDate(moment().subtract(1, 'years').format('YYYY/MM/DD'));
                if (onBeforeCalendar) setOnBeforeCalendar();
                else if (onAfterCalendar) setOnAfterCalendar();
                break;
            case 1:
                setAfterSelectedDate(moment().format('YYYY/MM/DD'));
                setBeforeSelectedDate(moment().subtract(1, 'months').format('YYYY/MM/DD'));
                if (onBeforeCalendar) setOnBeforeCalendar();
                else if (onAfterCalendar) setOnAfterCalendar();
                break;
            case 2:
                setAfterSelectedDate(moment().format('YYYY/MM/DD'));
                setBeforeSelectedDate(moment().subtract(1, 'weeks').format('YYYY/MM/DD'));
                if (onBeforeCalendar) setOnBeforeCalendar();
                else if (onAfterCalendar) setOnAfterCalendar();
                break;
            case 3:
                setAfterSelectedDate(moment().format('YYYY/MM/DD'));
                setBeforeSelectedDate(moment().format('YYYY/MM/DD'));
                if (onBeforeCalendar) setOnBeforeCalendar();
                else if (onAfterCalendar) setOnAfterCalendar();
                break;
            case 4:
                setAfterSelectedDate('');
                setBeforeSelectedDate('');
                setOnBeforeCalendar();
                break;
            default:
                break;
        }
    }, [selectedPeriod]);

    // Data Fetching
    useEffect(() => {
        const getDiagnosisList = async () => {
            return await calculatePageApi
                .getPaymentList('1', loginData.token)
                .then((res) => {
                    console.log(res);
                    const sortedData = res.data.list.sort((a, b) => {
                        return b.diagnosisTimestamp - a.diagnosisTimestamp;
                    });
                    setInitData(sortedData);
                })
                .catch((err) => console.log(err));
        };

        getDiagnosisList();
    }, [beforeSelectedDate, afterSelectedDate, loginData.token]);

    // handlePeriodFilter Dropdown
    const handleDropdown = (e: React.MouseEvent, idx: number, type: string) => {
        e.stopPropagation();
        if (type === 'period') {
            setOnSelectedPeriodDropdown();
            setSelectedPeriod(idx);
        } else {
            setOnSearchFilterDropdown();
            setselectedSearchFilter(idx);
        }
        setSearchValue('');
        setPage(1);
    };

    // reset Button function
    const handleResetButton = () => {
        console.log('reset');
    };

    return (
        <DiagnosisLayout>
            <DiagnosisInnerLayout>
                <HospitalPageLabel onClick={() => window.location.reload()}>{calculatePageText.pageLabel}</HospitalPageLabel>
                <PageTitle $marginbottom='24px'>{calculatePageText.pageTitle}</PageTitle>
                <Divider />
                <FilterWrapper></FilterWrapper>
                <AdminCalculatePagination initNumber={initData?.length} currentData={initData} handleResetButton={handleResetButton} />
            </DiagnosisInnerLayout>
        </DiagnosisLayout>
    );
};

export default AdminCalculatePage;

const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${palette.testGray};
    margin-bottom: 16px;
`;
