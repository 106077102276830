import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useBlocker, useLocation, useNavigate } from "react-router-dom";
import SurveyResult from "assets/Icons/ETC/Tablet/survey_result.json";
import styled from "styled-components";
import Lottie from "react-lottie";
import { governmentPaths } from "constants/path";
import { font } from "styles/Global/globalStyles";
import { useGovernmentHeaderStore } from "store/useGovernmentHeaderStore";
import { palette } from "styles/Global/globalStyles";

const GovernmentCompletePage = () => {
  const navigate = useNavigate();
  const { setInit, setHeaderText, setIsVisibleProgressbar } =
    useGovernmentHeaderStore();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: SurveyResult,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useBlocker(({ currentLocation, nextLocation }) => {
    return currentLocation.pathname === governmentPaths.complete;
  });

  useEffect(() => {
    setInit();
    setHeaderText("검사 완료");
    setIsVisibleProgressbar(false);
  }, []);

  return (
    <CompletePageLayout>
      <ContentsLayout>
        <Lottie options={defaultOptions} width={140} height={140} />
        <ContentsTextBox>
          <TitleTextBox>{"모든 검사가 끝났습니다!"}</TitleTextBox>
          {`앞서 작성하신 메일로 결과지가 전송됩니다.\n상담을 위해 상담 전문가가 연락을 드릴 수 있습니다.`}
        </ContentsTextBox>
      </ContentsLayout>
    </CompletePageLayout>
  );
};

export default GovernmentCompletePage;

const TitleTextBox = styled.div`
  white-space: pre-line;
  font-size: 24px;
  color: ${palette.textNormal};
  font-size: ${font.size[24]};
  font-weight: ${font.weight.semiBold};
`;

const ContentsTextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: pre-line; /* 텍스트 안의 줄바꿈 문자를 인식하여 줄바꿈을 적용 */
  color: ${palette.gray};
  font-size: ${font.size[14]};
  font-weight: ${font.weight.regular};
  gap: 8px;
`;

const ContentsLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 80px;
  text-align: center;
  gap: 16px;
  height: calc(100vh - 52px);
`;

const CompletePageLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 104px);
`;
