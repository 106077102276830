export const commonText = {
    cancel: '취소',
    password: '비밀번호',
    id: '아이디',
    login: '로그인',
    emailId: '이메일 아이디',
    confirmPassword: '비밀번호 확인',
    hospitalName: '병원명',
    name: '이름',
    phoneNumber: '연락처',
    out: '나가기',
    keepGoing: '계속 진행하기',
    stay: '머무르기',
    logout: '로그아웃',
    diagnosis: '진료',
    findId: '아이디 찾기',
    resetPassword: '비밀번호 재설정',
    signUp: '회원가입',
    confirm: '확인',
    showAll: '전체보기',
    no: '아니요',
};

export const commonErrText = {
    required: '필수 정보입니다.',
    email: '이메일 형식을 확인해주세요.',
    password: '영문, 숫자 포함 8자 이상 입력해주세요.',
    confirmPassword: '비밀번호가 일치하지 않습니다.',
    phoneNumber: '연락처를 정확히 입력해주세요.',
    identityNumber: '주민등록번호를 정확히 입력해주세요.',
    requiredSchoolAgeAndSemester: '필수 정보입니다. 학령과 학기 모두 선택해주세요.',
    requiredYearAndMonth: '필수 정보입니다. 연도와 월을 모두 선택해주세요.',
};

export const ButtonText = {
    goLoginPage: '로그인 페이지로 이동',
    sendLinkToEmail: '이메일로 링크 보내기',
};

export const palceholderText = {
    emailInput: '아이디 (이메일)',
};
