import { DropdownLayout } from './DropdownForms';
import styled from 'styled-components';
import { font, palette } from 'styles/Global/globalStyles';
import Close from 'assets/Icons/DeleteIcon/Close';
import moment from 'moment';
import { useAlarmListStore } from 'store/useToastStore';
import DoctorIcon from 'assets/Icons/Doctor/DoctorIcon';
import {
    Comment,
    Date,
    DiagnosisTimeWrapper,
    DoctoInfoWrapper,
    GlobalToastTextWrapper,
    InfoContainer,
    InfoWrapper,
    MainText,
    SubText,
    Title,
    ToastPatientName,
    UserInfoWrapper,
} from 'styles/Toast/toastStyles';
import NoneAlarmIcon from 'assets/Icons/Alarm/NoneAlarmIcon';
import { MemberWhiteBadge, RoundBadgeXS } from 'components/Common/Badge';
import Noti_Change from 'assets/Icons/ToastIcons/Noti_Change';
import Noti_Add from 'assets/Icons/ToastIcons/Noti_Add';
import Noti_Delete from 'assets/Icons/ToastIcons/Noti_Delete';
import Noti_Start from 'assets/Icons/ToastIcons/Noti_Start';
import Noti_Check from 'assets/Icons/ToastIcons/Noti_Check';
import { Border } from 'styles/Global/objectStyles';
import { BorderColor } from 'styles/Global/colorSemantic';
import GenderMan from 'assets/Icons/Gender/GenderMan';
import GenderWoman from 'assets/Icons/Gender/GenderWoman';
import Flag from 'assets/Icons/Common/Flag';
import { todayAlarmDropdownConstants } from 'constants/headerConstants';
import { commonText } from 'constants/commonConstants';

const TodayAlarmDropdown = ({ setIsAlarmDropdown }: { setIsAlarmDropdown: () => void }) => {
    const { alarmList } = useAlarmListStore();

    return (
        <DropdownLayout $position='absolute' $width='360px' $height='640px' $padding='20px 24px 28px 20px' $gap='20px' $right='0' $top='40px' $borderRadius='12px'>
            <AlarmTitleContainer>
                <AlarmTitleWrapper>
                    <AlarmTitle>{todayAlarmDropdownConstants.alarmTitle}</AlarmTitle>
                    <AlarmTitleTime>{moment().format('YYYY년 MM월 DD일')}</AlarmTitleTime>
                </AlarmTitleWrapper>

                <button onClick={setIsAlarmDropdown}>
                    <Close />
                </button>
            </AlarmTitleContainer>
            {alarmList.length !== 0 ? (
                <AlarmListContainer>
                    {alarmList.map((alarm, idx) => {
                        return (
                            <AlarmListWrapper key={idx}>
                                <IconWrapper>
                                    {(alarm.data?.code === '100' || alarm.data?.code === '400') && <Noti_Add />}
                                    {(alarm.data?.code === '101' || alarm.data?.code === '300' || alarm.data?.code === '501' || alarm.data?.code === '602') && <Noti_Change />}
                                    {(alarm.data?.code === '102' || alarm.data?.code === '500' || alarm.data?.code === '401' || alarm.data?.code === '603') && <Noti_Delete />}
                                    {alarm.data?.code === '202' && <Noti_Start />}
                                    {(alarm.data?.code === '200' || alarm.data?.code === '201' || alarm.data?.code === '203' || alarm.data?.code === '204') && <Noti_Check />}
                                </IconWrapper>
                                <GlobalToastTextWrapper>
                                    <Title>{alarm.title}</Title>
                                    <Comment>{alarm.comment}</Comment>
                                    {/* 담당의 추가, 제거 alarm */}
                                    {alarm.data?.doctorName && (
                                        <InfoContainer>
                                            <DoctoInfoWrapper>
                                                <DoctorIcon />
                                                <MainText>{alarm.data?.doctorName}</MainText>
                                                <SubText>{todayAlarmDropdownConstants.doctor}</SubText>
                                            </DoctoInfoWrapper>
                                        </InfoContainer>
                                    )}
                                    {/* 유저 정보 변경 toast */}
                                    {alarm.data?.userName && (
                                        <InfoContainer>
                                            <DoctoInfoWrapper>
                                                <DoctorIcon />
                                                <MainText>{alarm.data.userName}</MainText>
                                                <MemberWhiteBadge>{alarm.data.userLevel === '0' ? todayAlarmDropdownConstants.member : todayAlarmDropdownConstants.manager}</MemberWhiteBadge>
                                            </DoctoInfoWrapper>
                                        </InfoContainer>
                                    )}
                                    {/* 담당의 제외한 alarm */}
                                    {alarm.data?.patientName && (
                                        <InfoContainer>
                                            <InfoWrapper>
                                                <RoundBadgeXS>{alarm.data?.regNum}</RoundBadgeXS>
                                                <UserInfoWrapper>
                                                    <ToastPatientName>{alarm.data.patientName}</ToastPatientName>
                                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                                        {alarm.data.patientGender === 'male' ? <GenderMan $width='16px' $height='16px' /> : <GenderWoman $width='16px' $height='16px' />}
                                                    </span>
                                                    <span style={{ fontWeight: '400' }}>({alarm.data.patientIdentityNumber})</span>
                                                </UserInfoWrapper>
                                            </InfoWrapper>
                                            {alarm.data.diagnosisTime && (
                                                <DiagnosisTimeWrapper>
                                                    {alarm.data.diagnosisTime} {commonText.diagnosis}
                                                </DiagnosisTimeWrapper>
                                            )}
                                        </InfoContainer>
                                    )}
                                    <Date>{alarm.data?.addTime}</Date>
                                </GlobalToastTextWrapper>
                            </AlarmListWrapper>
                        );
                    })}
                    <LastContainer>
                        <Flag /> {todayAlarmDropdownConstants.lastAlarm}
                    </LastContainer>
                </AlarmListContainer>
            ) : (
                <NoneAlarmListContainer>
                    <NoneAlarmIcon />
                </NoneAlarmListContainer>
            )}
        </DropdownLayout>
    );
};

export default TodayAlarmDropdown;

const AlarmTitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 26px;
`;
const AlarmTitleWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    gap: 8px;
`;

const AlarmTitle = styled.span`
    font-size: 20px;
    font-weight: ${font.weight.bold};
`;
const AlarmTitleTime = styled.span`
    font-size: 13px;
    font-weight: ${font.weight.medium};
`;

const AlarmListContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 546px;
    gap: 18px;
    overflow-y: auto;
`;

const NoneAlarmListContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 546px;
`;

const AlarmListWrapper = styled.div`
    display: flex;
    width: 100%;
    padding-bottom: 20px;
    gap: 12px;
    border-bottom: ${Border.border_1} solid ${BorderColor.color_border_light};
`;

const IconWrapper = styled.div`
    padding-top: 4px;
    width: 32px;
    height: 100%;
`;

const TestIcon = styled.div`
    width: 32px;
    height: 32px;
    background-color: ${palette.gray};
    border-radius: 99px;
`;

const LastContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 84px;
    padding: 20px 0 40px 0;
    color: ${palette.testGray};
    font-weight: ${font.weight.regular};
    gap: 6px;
`;
