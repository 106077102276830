import { AssessmentResponse } from "models/Tablet/AssessmentApiTypes";
import { palette } from "styles/Global/globalStyles";
import styled from "styled-components";

type Props = {
  index: number;
  currentQuestion: AssessmentResponse;
};

const groupNames: { [key: string]: string } = {
  "RCMAS-2": "불안검사",
  "K-CDI 2": "우울검사",
  "K-ARS-5": "ADHD검사",
};

const InspectionHeaderTitle = ({ index, currentQuestion }: Props) => {
  const isAdult = currentQuestion.subName.startsWith("부모보고");
  const parsedSubName = isAdult ? "보호자용" : "자녀용";
  const name = groupNames[currentQuestion.group] || "기본검사";

  return (
    <HeaderLayout>
      <IndexBox>{index}</IndexBox>
      <AssessmentNameLayout>
        <NameBox>{name}</NameBox>
        <TestCodeBox>{currentQuestion.group}</TestCodeBox>
      </AssessmentNameLayout>
      <AssessmentSubNameLayout isAdult={isAdult}>
        {parsedSubName}
      </AssessmentSubNameLayout>
    </HeaderLayout>
  );
};

export default InspectionHeaderTitle;

const HeaderLayout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const IndexBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 8px;
  padding: 1px;
  border: 1px solid ${palette.borderNormal};
`;

const AssessmentNameLayout = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

const NameBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${palette.textNeutral};
`;

const TestCodeBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${palette.textWeak};
`;

const AssessmentSubNameLayout = styled.div<{ isAdult: boolean }>`
  display: flex;
  padding: 2px 8px;
  border-radius: 99px;
  color: ${(props) => (props.isAdult ? palette.primary : palette.accentCyan)};
  background-color: ${(props) =>
    props.isAdult ? palette.primaryNormalOpacity : palette.cyanOpacity};
`;
