export const governmentAgreePageConstants = {
    title1: '성남시와 함께하는',
    title2: '부모양육, 자녀기질 검사',
    descTitle: '정확한 검사를 위해 동의가 필요합니다.',
    descText1: '*개인정보 수집 및 이용 동의',
    descText2:
        '해당 서비스는 (주)티엘씨헬스케어에서 제공하는 서비스로 검사 결과 제공 및 안내를 위해 검사자의 개인정보(성별, 생년월, 연락처 등)가 필요하며, 보다 자세한 개인정보 제공항목은 동의 내용에서 확인 하실 수 있습니다.',
    agreeDesc: '(주)티엘씨 헬스케어의 개인정보 수집 및 이용에 동의합니다.',
    buttonText: '시작하기',
};
