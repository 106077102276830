import React from "react";
import styled from "styled-components";
import { palette } from "styles/Global/globalStyles";

type Props = {
  progress: number;
};

const InspectionProgressbar = ({ progress }: Props) => {
  return (
    <ProgressbarTrackLayout>
      <Progress width={`${progress}%`} />
    </ProgressbarTrackLayout>
  );
};

export default InspectionProgressbar;

const ProgressbarTrackLayout = styled.div`
  width: 100%;
  height: 4px;
  background-color: ${palette.backgroundNormal};
  overflow: hidden;
`;

const Progress = styled.div<{ width: string }>`
  height: 100%;
  width: ${(props) => props.width || "0%"};
  background-color: ${palette.primary};
  transition: width 0.4s ease; // 부드럽게 변화하는 효과
`;
