import _ from 'lodash';

const assessmentsFilter = (data: any[], monthLived: number, grade: string) => {
    const resultData: any[] = [];
    const assessmentRange: { [key: string]: number[] } = {
        RS_CO_SG_CA: [2020, 2055 + 1],
        SCI2_CO_SG_E13: [2020, 2025 + 1],
        MindFitR_CO_SG_E: [2024, 2031 + 1],
        KBASC2_EX_SG_A: [2030, 2055 + 1],
        KRCMAS2_CO_SG_E: [2024, 2031 + 1],
        KRCMAS2_CO_SG_A: [2040, 2055 + 1],
    };

    const ageMonthfilteredData = _.filter(data, (item) => _.inRange(monthLived, _.get(item, 'minAgeMonth'), _.get(item, 'maxAgeMonth') + 1));
    const notMonthDaysFilteredData = _.filter(data, (item) => _.get(item, 'minAgeMonth') === _.get(item, 'maxAgeMonth'));
    const filteredData = [...ageMonthfilteredData, ...notMonthDaysFilteredData];

    _.filter(filteredData, (item) => {
        if (!assessmentRange[item.assessmentCode]) {
            resultData.push(item);
        } else {
            if (_.inRange(Number(grade), assessmentRange[item.assessmentCode][0], assessmentRange[item.assessmentCode][1])) {
                resultData.push(item);
            }
        }
    });

    return resultData;
};

export default assessmentsFilter;
