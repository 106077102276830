import { BasicButtonType } from "models/Common/buttonTypes";
import styled from "styled-components";
import {
  BackgroundColor,
  BorderColor,
  InverseColor,
  PrimaryColor,
  TextColor,
  OpacityColor,
  WarningColor,
} from "styles/Global/colorSemantic";
import { Body3_Medium_Button } from "styles/Global/typography";

const MobileBasicButton = ({
  children,
  $width,
  onClick,
  disabled,
  $height,
  $gap,
  $type,
  buttonType,
}: BasicButtonType) => {
  return (
    <MobileButton
      $width={$width}
      onClick={onClick}
      disabled={disabled}
      $height={$height}
      $gap={$gap}
      $type={$type}
      type={buttonType}
    >
      {children}
    </MobileButton>
  );
};

export default MobileBasicButton;

const MobileButton = styled(Body3_Medium_Button)<BasicButtonType>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.$width ?? "100%"};
  height: ${(props) => props.$height || "52px"};
  border-radius: 8px;
  gap: ${(props) => props.$gap || "0"};
  ${(props) =>
    props.$type === "fill" &&
    `
        background-color: ${PrimaryColor.color_primary_normal};
        color: ${InverseColor.color_inverse_white};
        &:active {
            background-color: ${PrimaryColor.color_primary_heavy};
            color: ${InverseColor.color_inverse_white};
        };
        &:disabled {
            background-color: ${BackgroundColor.color_background_primary_disabled}; // disabled 상태일 때의 배경색
            color: ${OpacityColor.color_opacity_white_80};
        };
    `};
  ${(props) =>
    props.$type === "anotherFill" &&
    `
        background-color: ${BackgroundColor.color_background_strong};
        color: ${TextColor.color_text_normal};
        &:active {
            background-color: ${BackgroundColor.color_background_over};
            color: ${TextColor.color_text_normal};
        };
        &:focus {
            background-color: ${BackgroundColor.color_background_over};
            color: ${TextColor.color_text_normal};
        };
    `};
  ${(props) =>
    props.$type === "outLine" &&
    `
        border: 1px solid ${BorderColor.color_border_strong};
        color: ${TextColor.color_text_light};
        background-color: ${BackgroundColor.color_background_white};
        &:active {
            border: 1px solid ${BorderColor.color_border_normal_press};
            background-color: ${BackgroundColor.color_background_primary_strong};
            color: ${TextColor.color_text_normal};
        };
        &:disabled {
            border: 1px solid ${BorderColor.color_border_normal};
            background-color: ${BackgroundColor.color_background_disabled}; // disabled 상태일 때의 배경색
            color: ${TextColor.color_text_weak};
        };
    `};
  ${(props) =>
    props.$type === "warning" &&
    `
        background-color: ${WarningColor.color_warning_normal};
        color: ${InverseColor.color_inverse_white};
        &:hover {
            background-color: ${WarningColor.color_warning_normal_hover};
            color: ${InverseColor.color_inverse_white};
        };
        &:active {
            background-color: ${WarningColor.color_warning_normal_press};
            color: ${InverseColor.color_inverse_white};
        };
    `};

  ${(props) =>
    props.$type === "confirm" &&
    `
        background-color: ${PrimaryColor.color_primary_normal};
        color: ${InverseColor.color_inverse_white};
        &:active {
            background-color: ${PrimaryColor.color_primary_normal};
            color: ${InverseColor.color_inverse_white};
        };
        &:focus {
            background-color: ${PrimaryColor.color_primary_normal};
            color: ${InverseColor.color_inverse_white};
        };
    `};
`;
