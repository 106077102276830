import { UseGovernmentHeaderStoreType } from 'models/Government/headerTypes';
import { ReactNode } from 'react';
import { create } from 'zustand';

export const useGovernmentHeaderStore = create<UseGovernmentHeaderStoreType>((set) => ({
    headerText: '',
    isVisibleProgressbar: false,
    progressPercent: 0,
    setHeaderText: (state: ReactNode) => {
        set(() => ({
            headerText: state,
        }));
    },
    setIsVisibleProgressbar: (isVisible: boolean) => {
        set(() => ({
            isVisibleProgressbar: isVisible,  
        }))
    },
    setProgressPercent: (state: number) => {
        set(() => ({
            progressPercent: state,  
        }))
    },
    setInit: () => {
        set(() => ({
            headerText: '',
            isVisibleProgressbar: false,  
            progressPercent: 0,
        }));
    },
}));
