import moment from 'moment';

export const calculateAge = (idNumber: string) => {
    const year = idNumber.slice(0, 2);
    const month = idNumber.slice(2, 4);
    const day = idNumber.slice(4, 6);
    const genderCode = idNumber[6];

    const currentYear = new Date().getFullYear();
    const century = currentYear % 100 >= Number(year) ? '20' : '19';
    const fullYear = century + year;

    let genderCodeError = true;

    // 성별 코드에 따라 년도 valid 체크
    if (century === '19' && (genderCode === '1' || genderCode === '2' || genderCode === '5' || genderCode === '6')) {
        genderCodeError = false;
    } else if (century === '20' && (genderCode === '3' || genderCode === '4' || genderCode === '7' || genderCode === '8')) {
        genderCodeError = false;
    } else {
        genderCodeError = true;
    }

    const birthDate = `${fullYear}/${month}/${day}`;

    // 현재 날짜와 생년월일을 Date 객체로 변환
    const now = new Date();
    const birth = new Date(Number(fullYear), Number(month) - 1, Number(day)); // month는 0부터 시작하므로 1을 빼준다.

    // 만 나이 계산
    let age = now.getFullYear() - birth.getFullYear();
    if (now.getMonth() < birth.getMonth() || (now.getMonth() === birth.getMonth() && now.getDate() < birth.getDate())) {
        age--;
    }

    // 개월 수 계산
    let months = (now.getFullYear() - birth.getFullYear()) * 12;
    months -= birth.getMonth();
    months += now.getMonth();
    if (now.getDate() < birth.getDate()) {
        months--;
    }

    const gender =
        genderCode === '1' || genderCode === '3' || genderCode === '5' || genderCode === '7'
            ? 'male'
            : genderCode === '2' || genderCode === '4' || genderCode === '6' || genderCode === '8'
            ? 'female'
            : 'error';

    const ageString = `만 ${age}세 ${months % 12}개월`;
    return { birthDate, ageString, gender, genderCodeError };
};

export const calculateDaysLived = (identityNumber: string, diagnosisDateTime: string) => {
    // identityNumber에서 생년월일 추출
    const birthYearPrefix = parseInt(identityNumber.charAt(6)) < 3 ? 1900 : 2000;
    const birthYear = parseInt(identityNumber.substring(0, 2), 10) + birthYearPrefix;
    const birthMonth = parseInt(identityNumber.substring(2, 4), 10) - 1; // month는 0부터 시작
    const birthDay = parseInt(identityNumber.substring(4, 6), 10);

    // diagnosisDateTime에서 년, 월, 일
    const diagnosisYear = parseInt(diagnosisDateTime.substring(0, 4), 10);
    const diagnosisMonth = parseInt(diagnosisDateTime.substring(4, 6), 10) - 1; // month는 0부터 시작
    const diagnosisDay = parseInt(diagnosisDateTime.substring(6, 8), 10);

    // diagnosisDateTime과 생일을 moment 객체로 생성
    const diagnosisDate = moment([diagnosisYear, diagnosisMonth, diagnosisDay]);
    const birthDate = moment([birthYear, birthMonth, birthDay]);

    // 두 날짜 사이의 차이 계산
    const monthLived = diagnosisDate.diff(birthDate, 'months');

    return monthLived;
};

// 만 5세부터 19세까지 해당하는 년도와 월 배열을 계산
export const calculateYearAndMonth = () => {
    let today = new Date();
    let year = today.getFullYear();
    let month = today.getMonth() + 1; // JavaScript에서 월은 0부터 시작

    let yearMonth: [number, number[]][] = [];

    for (let i = 60; i <= 228; i++) {
        let date = new Date();
        date.setMonth(date.getMonth() - i);

        if (date.getFullYear() >= year - 19 && date.getFullYear() <= year - 5) {
            let yearIndex = yearMonth.findIndex((item) => item[0] === date.getFullYear());
            if (yearIndex === -1) {
                yearMonth.push([date.getFullYear(), [date.getMonth() + 1]]);
            } else {
                if (!yearMonth[yearIndex][1].includes(date.getMonth() + 1)) {
                    yearMonth[yearIndex][1].push(date.getMonth() + 1);
                }
            }
        }
    }

    // 현재 년도에서 만 5세에 해당하는 월을 제거
    let currentYearIndex = yearMonth.findIndex((item) => item[0] === year - 5);
    if (currentYearIndex !== -1) {
        yearMonth[currentYearIndex][1] = yearMonth[currentYearIndex][1].filter((m) => m < month);
    }

    // 만 19세가 되는 년도에서 현재 월보다 큰 월을 제거
    let nineteenYearIndex = yearMonth.findIndex((item) => item[0] === year - 19);
    if (nineteenYearIndex !== -1) {
        yearMonth[nineteenYearIndex][1] = yearMonth[nineteenYearIndex][1].filter((m) => m <= 12);
    }

    // 각 년도의 월 배열을 오름차순으로 정렬
    yearMonth.sort((a, b) => a[0] - b[0]);
    yearMonth.forEach((item) => item[1].sort((a, b) => a - b));

    return yearMonth;
};
