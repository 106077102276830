import { codeConfirmPageApi } from "api/Government/codeConfirmPageApi";
import MobileBasicButton from "components/Buttons/MobileBasicButton";
import { MobileBasicInputWithLabel } from "components/Input/BasicInput";
import { governmentCodeConfirmPageConstants } from "constants/\bGovernment/governmentCodeConfirmPageConstants";
import { governmentPaths } from "constants/path";

import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGovernmentHeaderStore } from "store/useGovernmentHeaderStore";
import styled from "styled-components";
import {
  AgreeAndCodeConfirmTitle,
  TitleContainer,
} from "styles/Global/Government/agreeAndCodeConfirmPageStyles";

const GovernmentCodeConfirmPage = () => {
  const navigate = useNavigate();

  const [code, setCode] = useState("");
  const [codeErr, setCodeErr] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const { setHeaderText, setInit } = useGovernmentHeaderStore();

  useEffect(() => {
    setHeaderText(governmentCodeConfirmPageConstants.headerText);
    return () => setInit();
  }, []);

  useEffect(() => {
    let isInput = code.length !== 0;
    setButtonDisabled(!isInput);

    // return () => setButtonDisabled(true);
  }, [codeErr, code]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const upperCaseValue = e.target.value.toUpperCase();
    setCode(upperCaseValue);
    setCodeErr("");
  };

  const handleBlurError = () => {
    if (!code) {
      setCodeErr(governmentCodeConfirmPageConstants.errMsg);
    } else {
      setCodeErr("");
    }
  };

  const clickNext = async () => {
    const response = await codeConfirmPageApi.verifyVoucherCode({ code: code });

    if (response.status === "ok") {
      if (response.data.voucher!.status !== 0) {
        setCodeErr(governmentCodeConfirmPageConstants.apiErrText);
      } else {
        navigate(governmentPaths.ready, {
          replace: true,
          state: response.data,
        });
      }
    } else {
      if (response.error) {
        if (response.error!.code === 1002) {
          setCodeErr(governmentCodeConfirmPageConstants.apiUsedErrText);
          return;
        }
        setCodeErr(governmentCodeConfirmPageConstants.apiErrText);
      }
    }
  };

  const enterKey = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      clickNext();
    }
  };

  return (
    <Layout>
      <TitleContainer>
        <AgreeAndCodeConfirmTitle>
          {governmentCodeConfirmPageConstants.title1}
        </AgreeAndCodeConfirmTitle>
        <AgreeAndCodeConfirmTitle>
          {governmentCodeConfirmPageConstants.title2}
        </AgreeAndCodeConfirmTitle>
      </TitleContainer>
      <MobileBasicInputWithLabel
        label={governmentCodeConfirmPageConstants.label}
        id="code"
        value={code}
        placeholder={governmentCodeConfirmPageConstants.placeholder}
        onChange={handleInputChange}
        onBlur={handleBlurError}
        errorMsg={codeErr}
        enterKey={enterKey}
      />
      <MobileBasicButton
        onClick={clickNext}
        $type="fill"
        disabled={buttonDisabled}
      >
        {governmentCodeConfirmPageConstants.buttonText}
      </MobileBasicButton>
    </Layout>
  );
};

export default GovernmentCodeConfirmPage;

const Layout = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 32px;
`;
