import Loading from 'components/Common/Loading';
import React, { Suspense, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { GlobalStyles } from 'styles/Global/globalStyles';
import TableHeader from './TabletHeader';
import axios from 'axios';
import { useLeaveModalStateStore } from 'store/useLeaveModalStateStore';
import { tabletPaths } from 'constants/path';
import AlertDialog from 'components/Modal/AlertDialog';

const TabletApp = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { isLogoutModal, setIsLogoutModal } = useLeaveModalStateStore();

    const preventClose = (e: BeforeUnloadEvent) => {
        e.preventDefault();
        e.returnValue = '';
    };

    useEffect(() => {
        axios.interceptors.request.use(
            (config) => {
                setLoading(true);
                return config;
            },
            (error) => {
                setLoading(false);
                return Promise.reject(error);
            }
        );
        axios.interceptors.response.use(
            (response) => {
                setLoading(false);
                return response;
            },
            (error) => {
                if (error.response.status === 534) {
                    localStorage.clear();
                    setIsLogoutModal(true);
                    setLoading(false);
                    return navigate(tabletPaths.managerLogin, { replace: true });
                }
                console.log(error, 'axiosInterceptResponseError');
                setLoading(false);
                return Promise.reject(error);
            }
        );
    }, []);

    useEffect(() => {
        (() => {
            window.addEventListener('beforeunload', preventClose);
        })();
        return () => {
            window.removeEventListener('beforeunload', preventClose);
        };
    }, []);

    return (
        <>
            <GlobalStyles />
            <AppLayout>
                {isLogoutModal && (
                    <AlertDialog
                        $title='일정 시간이 지나 로그아웃 되었습니다.'
                        $content='보안을 위해 일정 시간이 지나면, 로그인 기록이 만료되고'
                        $subContent='자동 로그아웃이 됩니다. 다시 로그인하여 서비스를 이용해주세요.'
                        $onClick={() => setIsLogoutModal(false)}
                    />
                )}
                <Loading loading={loading} />
                <Suspense fallback={<Loading loading={loading} />}>
                    <TableHeader />
                    <div className='outer'>
                        <Outlet />
                    </div>
                </Suspense>
            </AppLayout>
        </>
    );
};

export default TabletApp;

const AppLayout = styled.div`
    position: relative;
    display: flex;
    width: 100vw;
    height: auto;
    min-height: 100vh;
    flex-direction: column;
    touch-action: pan-up pan-down;
`;
