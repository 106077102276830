export const governmentCodeConfirmPageConstants = {
    title1: '성남시에서 전달받은',
    title2: '검사코드를 입력해주세요.',
    label: '검사코드',
    placeholder: '검사코드를 입력해주세요.',
    buttonText: '다음',
    errMsg: '필수 정보입니다. 정확히 입력해주세요.',
    headerText: '검사코드 입력',
    apiErrText: '일치하는 코드가 없습니다. 다시 입력해주세요.',
    apiUsedErrText: '이미 사용된 코드입니다. 다시 입력해주세요.'
};
