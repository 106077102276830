export const showAssessmentChildrenList = [
    { title: '불안검사', subTitle: 'RCMAS-2', desc: '나의 불안한 마음을 알아보는 검사', selected: true, assessmentCode: 'KRCMAS2_CO_SG_E', label: '자녀용' },
    { title: '불안검사', subTitle: 'RCMAS-2', desc: '나의 불안한 마음을 알아보는 검사', selected: true, assessmentCode: 'KRCMAS2_CO_SG_A', label: '자녀용' },
    { title: '우울검사', subTitle: 'K-CDI 2', desc: '나의 우울 심리를 알아보는 검사', selected: true, assessmentCode: 'KCDI2_CO_SG', label: '자녀용' },
];

export const showAssessmentParentsList = [
    { title: '우울검사', subTitle: 'K-CDI 2', desc: '자녀의 우울 심리를 알아보는 검사', selected: true, assessmentCode: 'KCDI2_CO_PG', label: '보호자용' },
    { title: 'ADHD검사', subTitle: 'K-ARS-5', desc: '자녀의 행동 발달 검사', selected: true, assessmentCode: 'KAT_ARS5_CO_TPG_PC', label: '보호자용' },
];
