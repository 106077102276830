import Check from 'assets/Icons/Check/Check';
import { highSchool, middleSchool, primarySchool, schoolAge } from 'constants/schoolAgeAndSemester';
import { calculateYearAndMonth } from 'functions/calculateAge';
import { YearAndMonthSelectDropdownProps } from 'models/Common/dropdownTypes';
import { useMemo } from 'react';
import styled from 'styled-components';
import { PrimaryColor, TextColor } from 'styles/Global/colorSemantic';
import { DropdownItem, DropdownLayout } from 'styles/Global/dropdownStyles';
import { font } from 'styles/Global/globalStyles';
import { Body3_Regular } from 'styles/Global/typography';

const YearAndMonthSelectDropdown = ({ id, setDropdown, $top, $height, $type, yearIdx, monthIdx, setSelectIdx, yearMonth }: YearAndMonthSelectDropdownProps) => {
    return (
        <DropdownLayout $height={$height} $type={$type || 'diagnosis'} $top={$top} $borderRadius={$type === 'mobile' ? '8px' : '4px'}>
            {id === 'birthDate'
                ? yearMonth.map((item, idx) => {
                      return (
                          <DropdownItem
                              className='dropdown-item'
                              key={idx}
                              $minHeight={$type === 'mobile' ? '48px' : '40px'}
                              $padding={$type === 'mobile' ? '0 16px' : '0 16px 0 20px'}
                              $type={$type || 'addDiagnosis'}
                              selected={yearIdx === idx}
                              onMouseDown={() => setDropdown(id, idx)}
                          >
                              <DropdownText $selected={yearIdx === idx}>{item[0]}년</DropdownText>
                              {yearIdx === idx && <Check $color={PrimaryColor.color_primary_normal} />}
                          </DropdownItem>
                      );
                  })
                : yearIdx !== undefined &&
                  yearMonth[yearIdx][1].map((item, idx) => {
                      return (
                          <DropdownItem
                              className='dropdown-item'
                              key={idx}
                              $minHeight={$type === 'mobile' ? '48px' : '40px'}
                              $padding={$type === 'mobile' ? '0 16px' : '0 16px 0 20px'}
                              $type={$type || 'addDiagnosis'}
                              selected={monthIdx === idx}
                              onMouseDown={() => setDropdown(id, idx)}
                          >
                              <DropdownText $selected={monthIdx === idx}>{item}월</DropdownText>
                              {monthIdx === idx && <Check $color={PrimaryColor.color_primary_normal} />}
                          </DropdownItem>
                      );
                  })}
        </DropdownLayout>
    );
};

export default YearAndMonthSelectDropdown;

const DropdownText = styled(Body3_Regular)<{ $selected?: boolean }>`
    color: ${TextColor.color_text_normal};
    font-weight: ${(props) => props.$selected && font.weight.semiBold};
`;
