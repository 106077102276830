import Icon_arrow_down from 'assets/Icons/Arrow/Icon_arrow_down';
import Icon_arrow_up from 'assets/Icons/Arrow/Icon_arrow_up';
import { BasicInputProps, InputProps, MobileBasicDropdownProps, MobileYearAndMonthSelectDropdownProps } from 'models/Common/inputTypes';
import { forwardRef } from 'react';
import styled from 'styled-components';
import { InputWithLabelIconWrapper, MobileBasicInput } from 'styles/Input/inputStyle';
import YearAndMonthSelectDropdown from './YearAndMonthSelectDropdown';

export const UnSelectInputDropdown = forwardRef<HTMLInputElement, MobileBasicDropdownProps>(
    ({ placeholder, value, errorMsg, onFocus, disabled, onBlur, id, onClick, onDropdown, setOnDropdown }, ref) => {
        return (
            <MobileInputWrapper>
                <MobileBasicInput ref={ref} id={id} placeholder={placeholder} value={value} $warning={errorMsg} onFocus={onFocus} disabled={disabled} onBlur={onBlur} onClick={onClick} readOnly />
                <InputWithLabelIconWrapper onClick={() => setOnDropdown(!onDropdown)} $top='16px' disabled={disabled}>
                    {!onDropdown ? <Icon_arrow_down /> : <Icon_arrow_up />}
                </InputWithLabelIconWrapper>
            </MobileInputWrapper>
        );
    }
);
export const MobileYearAndMonthSelectDropdown = forwardRef<HTMLInputElement, MobileYearAndMonthSelectDropdownProps>(
    ({ placeholder, value, errorMsg, onFocus, disabled, onBlur, id, onClick, onDropdown, setDropdown, yearIdx, monthIdx, setSelectIdx, yearMonth }, ref) => {
        return (
            <MobileInputWrapper>
                <MobileBasicInput
                    ref={ref}
                    id={id}
                    placeholder={placeholder}
                    value={value && (id === 'birthDate' ? value + '년' : value + '월')}
                    $warning={errorMsg}
                    onFocus={onFocus}
                    disabled={disabled}
                    onBlur={() => onBlur && onBlur(id)}
                    onClick={() => setDropdown(id)}
                    readOnly
                />
                <InputWithLabelIconWrapper onClick={() => setDropdown(id)} $top='16px' disabled={disabled}>
                    {!onDropdown ? <Icon_arrow_down /> : <Icon_arrow_up />}
                </InputWithLabelIconWrapper>
                {id && onDropdown && (
                    <YearAndMonthSelectDropdown
                        id={id}
                        setDropdown={setDropdown}
                        yearIdx={yearIdx}
                        monthIdx={monthIdx}
                        setSelectIdx={setSelectIdx}
                        yearMonth={yearMonth}
                        $height='176px'
                        $type='mobile'
                        $top='56px'
                    />
                )}
            </MobileInputWrapper>
        );
    }
);

// export const MobileUnSelectInputDropdownWithLabel = forwardRef<HTMLInputElement, MobileBasicDropdownProps>(
//     ({ desc, label, placeholder, id, disabled, errorMsg, value, onFocus, onBlur, onClick, onDropdown, setOnDropdown }, ref) => {
//         return (
//             <BasicInputLayout>
//                 <InputWrapper>
//                     <MobileInputLabel $marginBottom='0'>{label}</MobileInputLabel>
//                     {desc && <MobileInputSubLabel $marginBottom='0'>{desc}</MobileInputSubLabel>}
//                 </InputWrapper>
//                 <UnSelectInputDropdown
//                     ref={ref}
//                     id={id}
//                     disabled={disabled}
//                     placeholder={placeholder}
//                     value={value}
//                     errorMsg={errorMsg}
//                     onFocus={onFocus}
//                     onBlur={onBlur}
//                     onClick={onClick}
//                     onDropdown={onDropdown}
//                     setDropdown={() => setDropdown(id, idx)}
//                 />
//                 {errorMsg && <ErrorMsg $marginTop='0'>{errorMsg}</ErrorMsg>}
//             </BasicInputLayout>
//         );
//     }
// );

const MobileInputWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;
