import { formattedTime } from 'functions/calculateTime';
import { AnswerByQuestionInUserResponsesProps } from 'models/Hospital/viewDetailDiagnosisPageTypes';
import moment from 'moment';
import React, { RefObject, forwardRef } from 'react';
import styled from 'styled-components';
import { ColumnDivider } from 'styles/Global/commonStyles';
import { font, palette } from 'styles/Global/globalStyles';

type ResultCellProps = {
    $width: string;
    $type?: string;
};

const AnswerByQuestionInUserResponses = forwardRef(({ firstPageItems, otherPageItems, diagnosisInfoData, selectedAssessmentCode }: AnswerByQuestionInUserResponsesProps, ref) => {
    return (
        <Section ref={ref as RefObject<HTMLDivElement>}>
            <InnerLayout>
                <FirstPageHeaderContainer>
                    <FirstPageAssessmentCodeWrapper>{selectedAssessmentCode?.group}</FirstPageAssessmentCodeWrapper>
                    <FirstPageAssessmentNameWrapper>{selectedAssessmentCode?.assessmentName}</FirstPageAssessmentNameWrapper>
                </FirstPageHeaderContainer>
                <InfoLayout>
                    <InfoContainer>
                        <CellsWrapper>
                            <TitleCell>이름</TitleCell>
                            <BodyCell>{diagnosisInfoData.patientName}</BodyCell>
                        </CellsWrapper>
                        <CellsWrapper>
                            <TitleCell>성별</TitleCell>
                            <BodyCell>{diagnosisInfoData.patientGender === 'male' ? '남' : '여'}</BodyCell>
                        </CellsWrapper>
                    </InfoContainer>
                    <InfoContainer>
                        <CellsWrapper>
                            <TitleCell>검사일</TitleCell>
                            <BodyCell>{moment(diagnosisInfoData.diagnosisDateTime, 'YYYYMMDDHHmm').format('YYYY/MM/DD')}</BodyCell>
                        </CellsWrapper>
                        <CellsWrapper>
                            <TitleCell>검사 나이</TitleCell>
                            <BodyCell>{diagnosisInfoData.assessmentAge}</BodyCell>
                        </CellsWrapper>
                    </InfoContainer>
                </InfoLayout>
                <ResultTitleContainer>
                    <ResultCell $type='title' $width='64px'>
                        번호
                    </ResultCell>
                    <ResultCell $type='title' $width='568px'>
                        문항
                    </ResultCell>
                    <ResultCell $type='title' $width='348px'>
                        문항 응답
                    </ResultCell>
                </ResultTitleContainer>
                {firstPageItems.map((item, idx) => (
                    <ResultBodyContainer key={idx} type='firstPage'>
                        <ResultCell $width='64px'>{item.number}</ResultCell>
                        <ResultCell $width='568px'>{item.question}</ResultCell>
                        <ResultCell $width='348px'>{item.answer}</ResultCell>
                    </ResultBodyContainer>
                ))}
                <Page>Page 1</Page>
            </InnerLayout>
            {otherPageItems.map((items, idx) => (
                <InnerLayout key={idx}>
                    <OtherPageHeaderContainer>
                        <OtherPageAssessmentCodeWrapper>{selectedAssessmentCode?.group + ' ' + selectedAssessmentCode?.subName}</OtherPageAssessmentCodeWrapper>
                        <OtherPageAssessmentInfoWrapper>
                            <TextWrapper>
                                <GrayText>검사일</GrayText> <span>{moment(diagnosisInfoData.diagnosisDateTime, 'YYYYMMDDHHmm').format('YYYY/MM/DD')}</span>
                            </TextWrapper>
                            <ColumnDivider $height='16px' $backgroundcolor={palette.gray} $marginbottom='0' />
                            <TextWrapper>
                                <span>
                                    {diagnosisInfoData.patientName}
                                    <GrayText>님</GrayText>
                                </span>
                                <span>{diagnosisInfoData.assessmentAge}</span>
                            </TextWrapper>
                        </OtherPageAssessmentInfoWrapper>
                    </OtherPageHeaderContainer>
                    <ResultTitleContainer>
                        <ResultCell $type='title' $width='64px'>
                            번호
                        </ResultCell>
                        <ResultCell $type='title' $width='568px'>
                            문항
                        </ResultCell>
                        <ResultCell $type='title' $width='348px'>
                            문항 응답
                        </ResultCell>
                    </ResultTitleContainer>
                    {items.map((item, idx) => (
                        <ResultBodyContainer key={idx}>
                            <ResultCell $width='64px'>{item.number}</ResultCell>
                            <ResultCell $width='568px'>{item.question}</ResultCell>
                            <ResultCell $width='348px'>{item.answer}</ResultCell>
                        </ResultBodyContainer>
                    ))}
                    <Page>Page {idx + 2}</Page>
                </InnerLayout>
            ))}
        </Section>
    );
});

export default AnswerByQuestionInUserResponses;

const Section = styled.section`
    width: 1084px; /* 화면에서 보일 때의 너비 */

    @media print {
        width: 210mm; /* 인쇄 시 A4 용지 너비에 맞춤 */
        height: 100%; /* 인쇄 시 높이 자동 조정 */
        margin: 0 auto; /* 중앙 정렬 */
    }
`;

const InnerLayout = styled.div`
    position: relative;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 1528px;
    padding: 36px 52px 40px 52px;
    background-color: ${palette.white};
    border: 1px solid ${palette.testGray};
    border-bottom: none;
    margin-bottom: 32px;
    &:last-child {
        border-bottom: 1px solid ${palette.testGray};
    }

    @media print {
        min-height: 100vh; /* 인쇄 시 높이 자동 조정 */
        padding: 30px; /* 인쇄 시 여백 조정 */
    }
`;

const FirstPageHeaderContainer = styled.div`
    display: flex;
    width: 100%;
    height: 68px;
    margin-bottom: 22px;
    gap: 12px;
`;

const OtherPageHeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 68px;
    margin-bottom: 22px;
`;

const FirstPageAssessmentCodeWrapper = styled.span`
    display: flex;
    align-items: center;
    height: 100%;
    font-size: ${font.size[52]};
    font-weight: ${font.weight.bold};
    color: ${palette.black};
`;
const OtherPageAssessmentCodeWrapper = styled.span`
    display: flex;
    align-items: center;
    height: 100%;
    font-size: ${font.size[40]};
    font-weight: ${font.weight.bold};
    color: ${palette.black};
`;
const FirstPageAssessmentNameWrapper = styled.span`
    display: flex;
    align-items: center;
    height: 100%;
    font-size: ${font.size[24]};
    font-weight: ${font.weight.medium};
    color: ${palette.black};
`;
const OtherPageAssessmentInfoWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 28px;
    font-size: ${font.size[24]};
    font-weight: ${font.weight.medium};
    color: ${palette.black};
    gap: 16px;
`;

const InfoLayout = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100px;
    padding: 20px 36px;
    gap: 12px;
    border: 1px solid ${palette.testGray};
    border-radius: 4px;

    @media print {
        height: auto;
        padding: 10px; /* 인쇄 시 여백 조정 */
    }
`;

const InfoContainer = styled.div`
    display: flex;
    width: 100%;
    height: 24px;
    gap: 40px;
`;

const CellsWrapper = styled.div`
    display: flex;
    width: 50%;
    height: 100%;
    gap: 12px;
`;

const TitleCell = styled.span`
    width: 120px;
    height: 100%;
    line-height: 24px;
    font-size: ${font.size[18]};
    font-weight: ${font.weight.regular};
    color: ${palette.gray};
`;

const BodyCell = styled.span`
    width: 302px;
    height: 100%;
    line-height: 24px;
    font-size: ${font.size[18]};
    font-weight: ${font.weight.medium};
    color: ${palette.black};
`;

const ResultTitleContainer = styled.div`
    display: flex;
    width: 100%;
    height: 52px;
    border: 1px solid ${palette.testGray};
    border-radius: 4px 4px 0 0;
    margin-top: 22px;
    background-color: ${palette.lightGray};
`;

const ResultCell = styled.div<ResultCellProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${(props) => props.$width};
    height: 100%;
    gap: 12px;
    border-left: 1px solid ${palette.testGray};
    padding: ${(props) => (!props.$type ? '20px' : '0')};
    line-height: 26px;
    &:first-child {
        border-left: none;
    }

    @media print {
        padding: 10px; /* 인쇄 시 여백 조정 */
    }
`;
const ResultBodyContainer = styled.div<{ type?: string }>`
    display: flex;
    width: 100%;
    height: 92px;
    border: 1px solid ${palette.testGray};
    border-top: none;
    &:last-child {
        border-radius: 0 0 4px 4px;
    }

    @media print {
        height: ${(props) => (props.type === 'firstPage' ? '68px' : '70px')};
    }
`;

const Page = styled.span`
    position: absolute;
    bottom: 36px;
    right: 52px;
    // text-align: right;
    // width: 100%;
    height: 24px;
    line-height: 24px;
    font-size: ${font.size[16]};
    font-weight: ${font.weight.regular};
    color: ${palette.gray};
    margin-top: 17px;

    @media print {
        bottom: 10px; /* 인쇄 시 위치 조정 */
        right: 10px; /* 인쇄 시 위치 조정 */
    }
`;

const GrayText = styled.span`
    color: ${palette.gray};
`;

const TextWrapper = styled.div`
    display: flex;
    height: 100%;
    font-size: ${font.size[20]};
    font-weight: ${font.weight.regular};
    align-items: center;
    gap: 10px;
`;
