import { useEffect, useState } from 'react';
import { equalTo, onValue, orderByKey, query, ref } from 'firebase/database';
import { realtimeDatabase } from 'firebaseConfig';
import { useLoginStore } from 'store/useLoginDataStore';
import moment from 'moment';
import { GetDiagnosisListApiResponse, TodayDiagnosisResponse } from 'models/Hospital/hospitalApiTypes';

// firebase rtdb 연결 및 Data 편집해서 가져오기
const useConnectRtdb = () => {
    const { loginData } = useLoginStore();

    const [data, setData] = useState<GetDiagnosisListApiResponse['data']>();

    useEffect(() => {
        const diagnosisRef = ref(realtimeDatabase, 'diagnosis');
        const q = query(diagnosisRef, orderByKey(), equalTo(loginData.hospitalId));

        const unsubscribe = onValue(q, (snapshot) => {
            const data = snapshot.val() as TodayDiagnosisResponse;
            if (data && data[loginData.hospitalId]) {
                const today = moment().format('YYYYMMDD');
                const todayDiagnosis = Object.values(data[loginData.hospitalId]).filter((item: { diagnosisDateTime: string }) => item.diagnosisDateTime.slice(0, 8) === today);
                const sorted = [...todayDiagnosis].sort((a, b) => b.diagnosisDateTime.localeCompare(a.diagnosisDateTime));
                setData(sorted);
            }
        });

        return () => {
            unsubscribe();
        };
    }, [loginData.hospitalId]);

    return data;
};

export default useConnectRtdb;
