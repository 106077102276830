import { BadgeProps } from 'models/Common/badgeTypes';
import { ChildrenType, MobileBadgeType, MobileNumberBadgeType, UnSelectBadgeType } from 'models/Common/commonTypes';
import React from 'react';
import styled from 'styled-components';
import { Accent, BackgroundColor, BorderColor, InverseColor, OpacityColor, PrimaryColor, TextColor } from 'styles/Global/colorSemantic';
import { Border, Radius } from 'styles/Global/objectStyles';
import { Body3_Medium, Body3_Medium_Button, Body3_SemiBold, Body5_Medium, Body5_SemiBold, Caption1_Medium, Caption1_SemiBold } from 'styles/Global/typography';

export const RoundBasicBadge = ({ children }: ChildrenType) => {
    return (
        <RoundBasicBadgeLayout>
            <RoundBasicBadgeText>{children}</RoundBasicBadgeText>
        </RoundBasicBadgeLayout>
    );
};
export const RoundBadgeXS = ({ children }: ChildrenType) => {
    return (
        <RoundBadgeXSLayout>
            <RoundBasicBadgeText>{children}</RoundBasicBadgeText>
        </RoundBadgeXSLayout>
    );
};
export const RoundBadgeS = ({ children }: ChildrenType) => {
    return (
        <RoundBadgeSLayout>
            <RoundBasicBadgeText>{children}</RoundBasicBadgeText>
        </RoundBadgeSLayout>
    );
};

export const RoundBadgeSelectS = ({ children, $selected }: BadgeProps) => {
    return (
        <RoundBadgeSelectSLayout $selected={$selected}>
            <RoundBadgeSelectText>{children}</RoundBadgeSelectText>
        </RoundBadgeSelectSLayout>
    );
};

export const RoundBadgeSelectLButton = ({ children, $selected, onClick }: BadgeProps) => {
    return (
        <RoundBadgeSelectLayout $selected={$selected} onClick={onClick}>
            {$selected ? <RoundBadgeSelectLText>{children}</RoundBadgeSelectLText> : <RoundBadgeNonSelectLText>{children}</RoundBadgeNonSelectLText>}
        </RoundBadgeSelectLayout>
    );
};

export const SquareLineSBadge = ({ children }: ChildrenType) => {
    return (
        <SquareLineSBadgeLayout>
            <SquareLineSBadgeText>{children}</SquareLineSBadgeText>
        </SquareLineSBadgeLayout>
    );
};

export const MemberWhiteBadge = ({ children, onMouseEnter, onMouseLeave, $hover }: BadgeProps) => {
    return (
        <MemberWhiteBadgeLayout onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} $hover={$hover}>
            <MemberWhiteBadgeText>{children}</MemberWhiteBadgeText>
        </MemberWhiteBadgeLayout>
    );
};

export const MemberBlueBadge = ({ children, onMouseEnter, onMouseLeave }: BadgeProps) => {
    return (
        <MemberBlueBadgeLayout onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <MemberBlueBadgeText>{children}</MemberBlueBadgeText>
        </MemberBlueBadgeLayout>
    );
};

export const MemberGrayBadge = ({ children, onMouseEnter, onMouseLeave }: BadgeProps) => {
    return (
        <MemberGrayBadgeLayout onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <MemberGrayBadgeText>{children}</MemberGrayBadgeText>
        </MemberGrayBadgeLayout>
    );
};

export const SelectBadge = ({ children, $selected, onClick }: BadgeProps) => {
    return (
        <SelectBadgeLayout $selected={$selected} onClick={onClick}>
            {children}
        </SelectBadgeLayout>
    );
};

export const TodayAlarmRoundBadgeXS = ({ children }: ChildrenType) => {
    return (
        <TodayAlarmRoundBadgeXSLayout>
            <RoundBasicBadgeText>{children}</RoundBasicBadgeText>
        </TodayAlarmRoundBadgeXSLayout>
    );
};

export const MobileBadgeCyan = ({ children, $position }: MobileBadgeType) => {
    return (
        <MobileBadgeCyanLayout $position={$position}>
            <MobileBadgeCyanText>{children}</MobileBadgeCyanText>
        </MobileBadgeCyanLayout>
    );
};
export const MobileBadgePrimary = ({ children, $position }: MobileBadgeType) => {
    return (
        <MobileBadgePrimaryLayout $position={$position}>
            <MobileBadgePrimaryText>{children}</MobileBadgePrimaryText>
        </MobileBadgePrimaryLayout>
    );
};

export const UnSelectBadge = ({ children, $selected }: UnSelectBadgeType) => {
    return (
        <UnSelectBadgeLayout $selected={$selected}>
            <UnSelectBadgeText>{children}</UnSelectBadgeText>
        </UnSelectBadgeLayout>
    );
};
export const UnSelectFilterSmallBadge = ({ children, $selected }: UnSelectBadgeType) => {
    return <UnSelectFilterSmallBadgeLayout $selected={$selected}>{children}</UnSelectFilterSmallBadgeLayout>;
};
export const UnSelectFilterFilteringSmallBadge = ({ children, $selected }: UnSelectBadgeType) => {
    return <UnselecteFilterFilteringBadgeLayout $selected={$selected}>{children}</UnselecteFilterFilteringBadgeLayout>;
};

export const UnselectFilterBadge = ({ children, $selected, $onClick, $onMouseOver, $index }: UnSelectBadgeType) => {
    return (
        <UnselecteFilterBadgeLayout
            $selected={$selected}
            onClick={$onClick}
            onMouseOver={() => $onMouseOver && $index !== undefined && $onMouseOver($index)}
            onMouseOut={() => $onMouseOver && $onMouseOver(0)}
        >
            {children}
        </UnselecteFilterBadgeLayout>
    );
};

export const SelectFilterBadge = ({ children, $selected, $onClick, $onMouseOver, $index }: UnSelectBadgeType) => {
    return (
        <SelecteFilterBadgeLayout
            $selected={$selected}
            onClick={$onClick}
            onMouseOver={() => $onMouseOver && $index !== undefined && $onMouseOver($index)}
            onMouseOut={() => $onMouseOver && $onMouseOver(0)}
        >
            {children}
        </SelecteFilterBadgeLayout>
    );
};

export const MobileNumberBedge = ({ children, $type }: MobileNumberBadgeType) => {
    return <MobileNumberBadge $type={$type}>{children}</MobileNumberBadge>;
};

const MobileNumberBadge = styled(Caption1_Medium)<{ $type: string }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border: 1px solid ${(props) => (props.$type === 'strong' ? BorderColor.color_border_heavy : BorderColor.color_border_normal)};
    border-radius: ${Radius.radius_8};
`;

const UnselecteFilterFilteringBadgeLayout = styled(Body5_Medium)<UnSelectBadgeType>`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px;
    padding: 6px 12px;
    border-radius: ${Radius.radius_circle};
    background-color: ${BackgroundColor.color_background_primary_heavy};
    color: ${PrimaryColor.color_primary_heavy};
    &:hover {
        background-color: ${BackgroundColor.color_background_primary_over};
    }
    &:focus {
        background-color: ${BackgroundColor.color_background_primary_over};
    }
`;
const UnselecteFilterBadgeLayout = styled(Body3_Medium_Button)<UnSelectBadgeType>`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    padding: 6px 14px;
    border-radius: ${Radius.radius_circle};
    background-color: ${BackgroundColor.color_background_neutral};
    color: ${TextColor.color_text_light};
    &:hover {
        background-color: ${BackgroundColor.color_background_strong};
    }
    &:focus {
        background-color: ${BackgroundColor.color_background_heavy};
    }
`;
const SelecteFilterBadgeLayout = styled(Body3_Medium_Button)<UnSelectBadgeType>`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    padding: 6px 14px;
    border-radius: ${Radius.radius_circle};
    background-color: ${PrimaryColor.color_primary_normal};
    color: ${InverseColor.color_inverse_white};
    &:hover {
        background-color: ${PrimaryColor.color_primary_strong};
    }
    &:focus {
        background-color: ${PrimaryColor.color_primary_heavy};
    }
`;

const UnSelectBadgeLayout = styled.div<UnSelectBadgeType>`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px;
    padding: 4px 12px;
    border-radius: ${Radius.radius_circle};
    background-color: ${(props) => (props.$selected ? BackgroundColor.color_background_strong : BackgroundColor.color_background_neutral)};
    // &:hover {
    //     background-color: ${BackgroundColor.color_background_strong};
    // }
    // &:focus {
    //     background-color: ${BackgroundColor.color_background_strong};
    // }
`;

const UnSelectFilterSmallBadgeLayout = styled(Body5_Medium)<UnSelectBadgeType>`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px;
    padding: 4px 12px;
    border-radius: ${Radius.radius_circle};
    background-color: ${BackgroundColor.color_background_neutral};
    &:hover {
        background-color: ${BackgroundColor.color_background_strong};
    }
    &:focus {
        background-color: ${BackgroundColor.color_background_strong};
    }
`;

const UnSelectBadgeText = styled(Body5_Medium)`
    color: ${TextColor.color_text_light};
`;
const RoundBasicBadgeLayout = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 26px;
    padding: 4px 8px;
    border-radius: ${Radius.radius_circle};
    border: ${Border.border_1} solid ${OpacityColor.color_opacity_primary_light_20};
`;
const RoundBadgeXSLayout = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 50px;
    height: 22px;
    padding: 2px 8px;
    border-radius: ${Radius.radius_circle};
    border: ${Border.border_1} solid ${OpacityColor.color_opacity_primary_light_20};
`;
const RoundBadgeSLayout = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 26px;
    padding: 2px 8px;
    border-radius: ${Radius.radius_circle};
    border: ${Border.border_1} solid ${OpacityColor.color_opacity_primary_light_20};
`;
const TodayAlarmRoundBadgeXSLayout = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 55px;
    height: 22px;
    padding: 2px 8px;
    border-radius: ${Radius.radius_circle};
    border: ${Border.border_1} solid ${OpacityColor.color_opacity_primary_light_20};
`;
const RoundBadgeSelectSLayout = styled.div<BadgeProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 26px;
    height: 26px;
    padding: 0 8px;
    border-radius: ${Radius.radius_circle};
    background-color: ${(props) => (props.$selected ? PrimaryColor.color_primary_normal : InverseColor.color_inverse_neutral)};
`;

const RoundBadgeSelectLayout = styled.button<BadgeProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    padding: 6px 14px;
    border-radius: ${Radius.radius_circle};
    background-color: ${(props) => props.$selected && PrimaryColor.color_primary_normal};
`;

const SelectBadgeLayout = styled.button<BadgeProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    padding: 0 20px;
    border-radius: ${Radius.radius_circle};
    border: ${(props) => !props.$selected && `${Border.border_1} solid ${BorderColor.color_border_normal}`};
    background-color: ${(props) => (props.$selected ? PrimaryColor.color_primary_normal : BackgroundColor.color_background_white)};
    gap: 4px;
    &:hover {
        border: ${(props) => !props.$selected && `${Border.border_1} solid ${BorderColor.color_border_strong}`};
        background-color: ${(props) => (props.$selected ? PrimaryColor.color_primary_strong : BackgroundColor.color_background_primary_neutral)};
    }
    &:focus {
        border: ${(props) => !props.$selected && `${Border.border_1} solid ${BorderColor.color_border_strong}`};
        background-color: ${(props) => (props.$selected ? PrimaryColor.color_primary_strong : BackgroundColor.color_background_primary_neutral)};
    }
`;

const SquareLineSBadgeLayout = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 26px;
    padding: 8px;
    border-radius: ${Radius.radius_4};
    border: ${Border.border_1} solid ${BorderColor.color_border_normal};
`;

const RoundBasicBadgeText = styled(Caption1_Medium)`
    color: ${PrimaryColor.color_primary_weak};
`;
const RoundBadgeSelectText = styled(Caption1_Medium)`
    color: ${InverseColor.color_inverse_white};
`;
const RoundBadgeSelectLText = styled(Body3_SemiBold)`
    color: ${InverseColor.color_inverse_white};
`;
const RoundBadgeNonSelectLText = styled(Body3_Medium)`
    color: ${TextColor.color_text_light};
`;
const SquareLineSBadgeText = styled(Caption1_Medium)`
    color: ${TextColor.color_text_light};
`;

const MemberWhiteBadgeLayout = styled.div<BadgeProps>`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    padding: 0 4px;
    border-radius: ${Radius.radius_4};
    border: ${Border.border_1} solid ${BorderColor.color_border_strong};
    ${(props) =>
        props.$hover &&
        `
    &:hover {
        border: ${Border.border_1} solid ${BorderColor.color_border_heavy};
        background-color: ${BackgroundColor.color_background_normal};
    }
    `}
`;
const MemberBlueBadgeLayout = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    padding: 0 4px;
    border-radius: ${Radius.radius_4};
    background-color: ${BackgroundColor.color_background_primary_strong};
`;

const MemberGrayBadgeLayout = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    padding: 0 4px;
    border-radius: ${Radius.radius_4};
    border: ${Border.border_1} solid ${BorderColor.color_border_heavy};
    background-color: ${BackgroundColor.color_background_normal};
`;

const MemberWhiteBadgeText = styled(Caption1_Medium)`
    color: ${TextColor.color_text_light};
`;

const MemberBlueBadgeText = styled(Caption1_SemiBold)`
    color: ${PrimaryColor.color_primary_normal};
`;

const MemberGrayBadgeText = styled(Caption1_Medium)`
    color: ${TextColor.color_text_neutral};
`;

const MobileBadgeCyanLayout = styled.div<{ $position: string | undefined }>`
    padding: 2px 8px;
    border-radius: ${Radius.radius_circle};
    background-color: ${Accent['color-accent-cyan-opacity-8']};
    ${(props) => props.$position === 'absolute' && 'position: absolute; top: 14px; right: 16px;'};
`;
const MobileBadgePrimaryLayout = styled.div<{ $position: string | undefined }>`
    padding: 2px 8px;
    border-radius: ${Radius.radius_circle};
    background-color: ${PrimaryColor['color_primary_light-8']};
    ${(props) => props.$position === 'absolute' && 'position: absolute; top: 14px; right: 16px;'};
`;

const MobileBadgeCyanText = styled(Body5_SemiBold)`
    color: ${Accent.color_accent_cyan};
`;

const MobileBadgePrimaryText = styled(Body5_SemiBold)`
    color: ${PrimaryColor.color_primary_normal};
`;
