import React, { Dispatch, SetStateAction } from "react";
import _, { update } from "lodash";
import CheckBox from "assets/Icons/ETC/Tablet/CheckBox";
import { ChoiceListType } from "models/Tablet/DiagnosisApiTypes";
import styled from "styled-components";
import { font, palette } from "styles/Global/globalStyles";
import { AssessmentResponse } from "models/Tablet/AssessmentApiTypes";
import { AnswerModel } from "models/Tablet/AnswerModel";

type Props = {
  noResponseList: ChoiceListType[];
  currentQuestion: AssessmentResponse;
  choiceList: ChoiceListType[];
  questionInfo: {
    prev: number;
    now: number;
    next: number;
    end: number;
  };
  setSelectedOverNoRes: Dispatch<SetStateAction<boolean>>;
  setNoResponseList: Dispatch<SetStateAction<ChoiceListType[]>>;
  setChoiceList: Dispatch<SetStateAction<ChoiceListType[]>>;
  setQuestionInfo: React.Dispatch<
    React.SetStateAction<{
      prev: number;
      now: number;
      next: number;
      end: number;
    }>
  >;
};

const InspectionComponent = ({
  currentQuestion,
  choiceList,
  setChoiceList,
  setSelectedOverNoRes,
}: Props) => {
  const createAnswer = (
    questionNum: number,
    choiceNum: number,
    score: number
  ) => {
    const model = new AnswerModel();
    model.questionNo = questionNum;
    model.choiceNo = choiceNum.toString();
    model.choiceScore = score.toString();
    return model;
  };

  const handleClick = (
    questionNo: number,
    choiceNum: number,
    score: number
  ) => {
    const answer = createAnswer(questionNo, choiceNum, score);

    const foundQuestion = _.find(
      choiceList,
      (e) => e.questionNo === questionNo
    );

    if (answer.choiceScore === "-1") {
      const isOver = checkOverNoResMaxCount(answer);

      if (isOver) {
        setSelectedOverNoRes(true);
        return;
      }
    }

    if (foundQuestion) {
      changeChoice(questionNo, choiceNum, score, answer);
      return;
    }

    setChoiceList([...choiceList, answer]);
  };

  const checkOverNoResMaxCount = (answer: AnswerModel) => {
    const noResCountList = _.filter(choiceList, (e) => e.choiceScore === "-1");

    return noResCountList.length >= currentQuestion.maxNoResCount;
  };

  const changeChoice = (
    questionNo: number,
    choiceNum: number,
    score: number,
    answer: AnswerModel
  ) => {
    const updateChoiceList = choiceList.map((item) => {
      return item.questionNo === questionNo
        ? {
            ...item,
            choiceNo: choiceNum.toString(),
            choiceScore: score.toString(),
          }
        : item;
    });

    setChoiceList((_) => [...updateChoiceList]);
  };

  return (
    <div>
      {currentQuestion.questions.map((question, questionNo) => (
        <QuestionLayout>
          <QuestionNumContainer>
            <QuestionNum>{`${(questionNo + 1)
              .toString()
              .padStart(2, "0")}`}</QuestionNum>
            <QuestionTotalNum>{`/ ${currentQuestion.questionCount}`}</QuestionTotalNum>
          </QuestionNumContainer>
          <QuestionText>
            {question.question === undefined
              ? `문항 ${questionNo + 1}`
              : `${question.question}`}
          </QuestionText>
          <AnswerContainer>
            {question.answers.map((item, index) => {
              return (
                <AnswerArticle
                  key={`${
                    currentQuestion.assessmentCode
                  }-${questionNo.toString()}-${item.number}`}
                  isSelected={choiceList.some(
                    (choice) =>
                      choice.questionNo === questionNo &&
                      choice.choiceNo === item.number.toString()
                  )}
                  isFirst={index === 0}
                  isLast={index === question.answers.length - 1}
                  onClick={() =>
                    handleClick(questionNo, item.number, item.score)
                  }
                >
                  {choiceList.some(
                    (choice) =>
                      choice.questionNo === questionNo &&
                      choice.choiceNo === item.number.toString()
                  ) ? (
                    <CheckBox />
                  ) : (
                    <UnCheckButton />
                  )}

                  <AnswerLabel>{item.answer}</AnswerLabel>
                </AnswerArticle>
              );
            })}
          </AnswerContainer>
        </QuestionLayout>
      ))}
    </div>
  );
};

export default InspectionComponent;

const QuestionLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 60px;
`;

const QuestionNumContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  row-gap: 10px;
  padding-bottom: 10px;
`;

const QuestionNum = styled.div`
  font-size: ${font.size[24]};
  font-weight: ${font.weight.bold};
`;

const QuestionTotalNum = styled.div`
  font-size: ${font.size[16]};
  color: ${palette.textInfo};
  padding-left: 2px;
  font-weight: ${font.weight.medium};
`;

const QuestionText = styled.div`
  font-size: ${font.size[16]};
  font-weight: ${font.weight.semiBold};
  padding-bottom: 16px;
`;

const AnswerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid #dbdbdb;
  font-size: ${font.size[16]};
  font-weight: ${font.weight.semiBold};
`;

const borderRadius = (isFirst: boolean, isLast: boolean) => {
  return `
    ${isFirst ? "8px" : "0px"} ${isFirst ? "8px" : "0px"} 
    ${isLast ? "8px" : "0px"} ${isLast ? "8px" : "0px"}
  `;
};

const AnswerArticle = styled.div<{
  isFirst: boolean;
  isLast: boolean;
  isSelected: boolean;
}>`
  height: 68px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 16px;
  background-color: ${(props) =>
    props.isSelected ? palette.primaryNormal : "transparent"};
  border-radius: ${(props) => borderRadius(props.isFirst, props.isLast)};
  border-bottom: 1px solid
    ${(props) => (props.isLast ? "transparent" : "#dbdbdb")};
`;

const AnswerLabel = styled.label`
  font-size: ${font.size[16]};
  font-weight: ${font.weight.regular};
  width: 100%;
  padding: 16px;
  border-radius: 8px;
`;
const UnCheckButton = styled.button`
  width: 28px;
  height: 28px;
  border-radius: 8px;
  border: 1px solid #999;
`;
