import axios from 'axios';
import { apiPaths } from 'constants/path';
import { tokenHeader } from 'functions/apiFunctions';
import { GetPatientsInfoApiResponse, GetResultDiagnosisApiResponse, PatientDiagnosisListApiResponse, UpdatePatientApiRqData, getAssessmentCodeResultResponse } from 'models/Hospital/hospitalApiTypes';

class ViewDetailPatientPageApi {
    async updatePatientInfo(rqData: UpdatePatientApiRqData, token: string): Promise<GetPatientsInfoApiResponse> {
        try {
            const response = await axios.put(apiPaths.updatePatient, rqData, {
                headers: tokenHeader(token),
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async getPatientInfo(url: string, token: string): Promise<GetPatientsInfoApiResponse> {
        try {
            const response = await axios.get(apiPaths.getPatientInfo + url, {
                headers: tokenHeader(token),
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async getPatientDiagnosisList(url: string, token: string): Promise<PatientDiagnosisListApiResponse> {
        try {
            const response = await axios.get(apiPaths.getPatientDiagnosisList + url, {
                headers: tokenHeader(token),
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async patientChangeStatus(rqData: { _id: string; status: number }, token: string): Promise<GetPatientsInfoApiResponse> {
        try {
            const response = await axios.put(apiPaths.patientChangeStatus, rqData, {
                headers: tokenHeader(token),
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async deletePatient(id: string, token: string): Promise<GetPatientsInfoApiResponse> {
        try {
            const response = await axios.delete(apiPaths.deletePatient + id, {
                headers: tokenHeader(token),
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async getResultDiagnosis(url: string, token: string): Promise<GetResultDiagnosisApiResponse> {
        try {
            const response = await axios.get(apiPaths.getResultDiagnosis + url, {
                headers: tokenHeader(token),
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async getAssessmentCodeResult(url: string, token: string): Promise<getAssessmentCodeResultResponse> {
        try {
            const response = await axios.get(apiPaths.findByCode + url, {
                headers: tokenHeader(token),
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}

export const viewDetailPatientPageApi = new ViewDetailPatientPageApi();
